import React from "react";
import { ZebecNetwork } from "../../../assets/gif";

const NetworkHero = () => {
  return (
    <div>
      <div className="text-xs sm:text-sm lg:text-base">
        <strong className="font-normal text-zebec-textGray ">
          Home / Zebec Instant Card /
        </strong>{" "}
        Countries and Networks
      </div>
      <div className="my-20 sm:flex items-end gap-5 md:gap-10 lg:gap-20">
        <div className="flex-1">
          <h2 className="text-2xl font-normal sm:text-4xl lg:text-6xl max-w-xl">
            Zebec Instant Card: Countries and Networks
          </h2>
        </div>
        <div className="flex-1 mt-5 sm:mt-0">
          <h1 className="max-w-xs text-xs sm:text-sm lg:text-base font-normal">
            The Zebec Instant Card is available in 138 countries around the
            world, powered by global Visa and Mastercard networks
          </h1>
        </div>
      </div>
      <div>
        <img src={ZebecNetwork} alt="" />
      </div>
    </div>
  );
};

export default NetworkHero;

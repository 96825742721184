import React from "react";
import FaqCard from "./FaqCard";
import { Link } from "react-router-dom";

const faqs = [
  {
    qst: "In Which Countries Is the Zebec Silver Card Accepted?",
    ans: (
      <>
        The Zebec Silver Card, denominated in USD, can be ordered in over 90
        countries and is accepted by 80 million merchants in 210 countries and
        territories where Mastercard® is accepted, providing extensive global
        access for your spending needs. As more fiat currencies are added to
        Zebec Silver, the number of countries where the card can be ordered will
        increase. The full list of countries where USD cards are available can
        be found{" "}
        <span>
          <Link to="/zebec-silver" className="gradient-text">
            here.
          </Link>
        </span>
      </>
    ),
  },
  {
    qst: "What Are the Fees to Order and Use the Card?",
    ans: "The Zebec Silver Card is a no-fee card, meaning there are no sign-up or transaction fees. Exchange fees may vary depending on the currency used. As additional fiat currencies are introduced in the coming weeks, you can avoid foreign exchange fees by matching your card’s currency with the country you plan to spend in.",
  },
  {
    qst: "Can I Use the Zebec Silver Card for Online Purchases?",
    ans: "Absolutely! The Zebec Silver Card is activated in seconds and is perfect for all your online shopping needs. Use it anywhere Mastercard is accepted online or connect it to Apple Pay or Google Pay with just a few taps.",
  },
  {
    qst: "What Should I Do if My Card Is Lost or Stolen?",
    ans: (
      <>
        In case of a lost or stolen card, immediately freeze your card through
        our dashboard and contact our customer support team{" "}
        <span>
          <Link
            to="mailto:support@zebec.io"
            className="gradient-text hover:border-b border-primary"
          >
            support@zebec.io
          </Link>
        </span>{" "}
        for further assistance and to order a replacement card.
      </>
    ),
  },
  {
    qst: "Is There a Spending Limit on the Card?",
    ans: (
      <>
        The Zebec Silver Card accommodates various spending needs with generous
        limits. You can load between $5 and $1,000 per card. Additionally, you
        can order multiple cards and spend up to $10,000 within 24 hours. If you
        are looking for a card with no spending limits, consider our{" "}
        <span>
          <Link
            to="https://zebec.mobilum.com/#/start"
            target="_blank"
            rel="noreferrer noopener"
            className="gradient-text"
          >
            Zebec Black.
          </Link>
        </span>
      </>
    ),
  },
  {
    qst: "Is the Silver Card reloadable?",
    ans: (
      <>
        Our Silver Card is a prepaid single use card. Multiple cards can be
        ordered, with a cumulative daily spend limit of up to $10,000. If you
        are interested in a reloadable card, please consider our debit card -
        <span>
          <Link
            to="https://zebec.mobilum.com/#/start"
            target="_blank"
            rel="noreferrer noopener"
            className="gradient-text"
          >
            Zebec Black
          </Link>
        </span>
        , also supported by Mastercard network and worldwide.
      </>
    ),
  },
];

const FAQs = () => {
  return (
    <>
      <div className="my-24">
        <div className=" gap-6 md:flex justify-between text-zebec-primary">
          <div className="max-w-[335px] lg:max-w-[538px]">
            <h2 className="text-[25px] font-normal lg:text-[40px] leading-[130%]">
              Welcome to the Future of Finance — Available Everywhere, for
              Everyone
            </h2>
            <p className="mt-5">Find Answers to Your Questions</p>
          </div>
          {/* faqs  */}
          <div className="mt-10 md:mt-0">
            {faqs.map((data, index) => {
              return <FaqCard {...data} key={index} index={index} />;
            })}
          </div>
        </div>
        <div className="mt-10 md:mt-14 rounded-2xl bg-[#F8F8FF] p-8 md:p-12">
          <p className="text-sm font-semibold text-[#6E6EA0]">USD INT</p>
          <p className="text-sm text-[#6E6EA0]">
            Issued by The Bancorp Bank, N.A., pursuant to license by Mastercard
            International.
            <br />
            Mastercard and the circles design are registered trademarks of
            Mastercard International Incorporated. The Bancorp Bank, N.A.;
            Member FDIC.
            <br />
            *See full list of countries{" "}
            <span>
              <Link to="/visa-master" className="underline">
                here
              </Link>
            </span>
            <br />
            Read more or contact us at{" "}
            <span>
              <Link to="mailto:cardsupport@zebec.io" className="underline">
                cardsupport@zebec.io
              </Link>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default FAQs;

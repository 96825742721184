import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "../../../assets/icons";

const FaqCard = ({ qst, ans, ans2, maxWidth, index }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {index === 1 ? (
        <div>
          <div className={maxWidth ? maxWidth : "max-w-[640px]"}>
            <div
              className={`flex items-center justify-between gap-6 border-b animation py-5 cursor-pointer ${
                open ? "border-[#C8A0FA]" : "border-zebec-black/[0.05]"
              }`}
              onClick={() => setOpen((preval) => !preval)}
            >
              <h2 className="text-zebec-primary font-normal text-[21px] lg:text-[29px] lg:leading-[120%]">
                Which countries and which fiat currencies are supported?
              </h2>
              <div
                className={`h-[40px] flex-shrink-0 w-[40px] rounded-full transition-all duration-300 ease-in-out  grid place-content-center
                  `}
              >
                {open ? (
                  <Icons.GradientDropdownCircleIcon />
                ) : (
                  <Icons.GradientRightCircleIcon />
                )}
              </div>
            </div>
            <div
              className={` text-zebec-primary overflow-hidden ${
                open ? "max-h-screen py-4" : "max-h-0"
              } transition-all duration-300 ease-in-out `}
            >
              <p>
                The Zebec Black is supported by the Mastercard network globally.
                Users from all countries, including our friends in China will be
                supported, except those countries sanctioned by OFAC, listed{" "}
                <strong
                  className="gradient-text font-normal hover:underline"
                  onClick={() => {
                    if (window !== undefined) {
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  <Link to="/visa-master">here.</Link>
                </strong>{" "}
              </p>
              <br />
              <p>
                Only stablecoins will be supported for cards issued in China.
              </p>
              <br />
              <p>
                EURO and USD will be offered at launch, with other fiat
                currencies added on a regular basis moving forward.{" "}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={maxWidth ? maxWidth : "max-w-[640px]"}>
            <div
              className={`flex items-center justify-between gap-6 border-b animation py-5 cursor-pointer ${
                open ? "border-[#C8A0FA]" : "border-zebec-black/[0.05]"
              }`}
              onClick={() => setOpen((preval) => !preval)}
            >
              <h2 className="text-zebec-primary font-normal text-[21px] lg:text-[29px] lg:leading-[120%]">
                {qst}
              </h2>
              <div className="h-[40px] flex-shrink-0 w-[40px] rounded-full transition-all duration-300 ease-in-out  grid place-content-center">
                {open ? (
                  <Icons.GradientDropdownCircleIcon />
                ) : (
                  <Icons.GradientRightCircleIcon />
                )}
              </div>
            </div>
            <div
              className={` text-zebec-primary overflow-hidden ${
                open ? "max-h-screen py-4" : "max-h-0"
              } transition-all duration-300 ease-in-out `}
            >
              {ans}
              {ans2 && (
                <>
                  <br />
                  <br />
                  {ans2}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FaqCard;

import React, { useState } from "react";
import { WorldIcon } from "../../../assets/icons";
import { visa_card_country_list } from "../../constants/country_lists";

const VisaCardCountries = () => {
  const countryDisplayOnLoad = 20;
  const [showMoreCountires, setShowMoreCountries] = useState(false);
  return (
    <div className="my-20">
      <p className="text-xl md:text-3xl lg:text-4xl max-w-[38rem]">
        Visa cards are exclusively available in the following countries:
      </p>
      <div className="flex flex-wrap gap-3 mt-10 items-center">
        {visa_card_country_list
          .slice(
            0,
            showMoreCountires
              ? visa_card_country_list.length
              : countryDisplayOnLoad
          )
          .map((data) => {
            return (
              <div
                key={data.country}
                className="rounded-full border border-zebec-grayText flex-shrink-0 flex items-center gap-2 px-2 py-2"
              >
                <img
                  alt=""
                  className="lg:w-8 lg:h-8 h-5 w-5 rounded-full object-cover"
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.code}.svg`}
                />
                <span className="text-zebec-black font-semibold text-xs md:text-sm lg:text-base">
                  {data.country}
                </span>
              </div>
            );
          })}
        <div
          className="rounded-full border border-primary flex-shrink-0 flex items-center gap-2 px-2 py-2 cursor-pointer"
          onClick={() => setShowMoreCountries(!showMoreCountires)}
        >
          <div className="w-8 h-8 grid place-content-center bg-primary rounded-full">
            <WorldIcon className="text-zebec-black h-5 w-5" />
          </div>
          <span className="font-semibold text-primary">
            {!showMoreCountires ? "+" : "-"}
            {visa_card_country_list.length - countryDisplayOnLoad}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VisaCardCountries;

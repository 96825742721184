export const LEGEND_PARAMS = {
  COWBOY: "cowboy",
  ICED: "iced",
  GUMSHOE: "gumshoe",
  JOE: "joe",
  SUGAR: "sugar",
  BURRRD: "burrrd",
  JAKEY: "jakey",
  CT: "ct",
  HERNAN: "hernan",
  METASAL: "metasal",
  AGENCIADESOLUCIONES: "agenciadesoluciones",
  RSANJIB: "rsanjib",
  NFTBOI: "nftboi",
  SOLANAANZ: "solanaANZ",
  ICOPANTERA: "icopantera",
  CHRIS: "chris",
  UPROCK: "uprock",
};

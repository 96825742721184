import React from "react";
import { HeroBg, ZebecSilverCardImg } from "../../../assets/images";
import * as Icons from "../../../assets/icons";
import { Button } from "../../shared/Button";

const HeroSection = () => {
  return (
    <>
      <div className="font-ppmori relative">
        <div className=" md:h-[600px] overflow-hidden  rounded-[30px] md:bg-[#CDDFF0] md:flex hero-wave-bg md:bg-right md:bg-bottom bg-no-repeat">
          <img
            src={HeroBg}
            className="w-full h-full object-right absolute top-0 right-0 hidden md:block"
            alt=""
          />
          <div className="hero-contents">
            {/* left side  */}
            <div className="flex-1 relative z-[1] pb-10 md:pb-0 px-10 lg:px-20 text-white flex flex-col justify-center">
              <h1 className="text-4xl md:text-5xl lg:text-[60px] lg:leading-[65px]">
                Zebec Silver
                <br /> Card
              </h1>
              <h2 className="text-[24px] font-normal lg:text-[28px] max-w-md md:mt-5 mt-2">
                Available Everywhere*
                <br /> for Everyone
              </h2>
              <div>
                <a
                  href="https://app.card.zebec.io/"
                  target="_blank"
                  rel="noreferrer"
                  className=""
                >
                  <Button
                    title={"Get Zebec Card"}
                    className="green-button-border my-5 md:mb-0 md:mt-10 text-sm lg:text-base rounded-full text-primary"
                    startIcon={
                      <div
                        className={
                          "w-10 h-10 rounded-full bg-primary grid place-content-center"
                        }
                      >
                        <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                      </div>
                    }
                  />
                </a>
              </div>
            </div>
            {/* right side  */}
            <div className="flex-1 relative z-[1] hero-gif-image my-auto pr-6">
              <img
                src={ZebecSilverCardImg}
                alt=""
                className="w-[100%] ml-auto my-auto z-[100]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;

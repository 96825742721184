import React from "react";
import FaqCard from "./FaqCard";

const faqs = [
  {
    qst: "What are the fees associated with the card?",
    ans: "The card has a load fee of approximately 1%. Any gas and exchange fees are always variable and may be additional.",
  },
  {},
  {
    qst: "Are all of the tokens integrated on Zebec Instant enabled on the Zebec Black?",
    ans: "Zebec Black will offer significantly more supported tokens at launch. All major tokens on BNB, Ethereum, Bitcoin, Polygon, Tron, CoreDAO are supported. Partners on our Instant card are to be confirmed and announced shortly.",
  },
  {
    qst: "What is Mobilum?",
    ans: "Mobilum is our backend processing partner at launch for Zebec Black. They are a fintech company that provides various payments and banking solutions for retail and institutional clients.",
  },
  {
    qst: "Is it reloadable?",
    ans: "Yes, the Zebec Black is reloadable as many times as needed. There are no limits to the funds that can be loaded onto the card.",
  },
  {
    qst: "How long for user verification and approval?",
    ans: "Live user data shows under a minute after all info by the client is submitted.",
  },
  {
    qst: "Can I withdraw cash from the ATM?",
    ans: "Yes. Once physical cards are launched in 8-12 weeks time, the fee for cash withdrawal will be $2USD plus 1% within the European Economic Association (EEA); outside EEA $2USD plus 2% of the amount withdrawn.",
  },
  {
    qst: "Which chains are enabled?",
    ans: "At launch BNB, Ethereum, Bitcoin, Polygon, Tron, CoreDAO will be enabled. Solana, BASE and other major chains will be integrated soon.",
  },
  {
    qst: "Do I have to use Mobilum Pay or can I add it to ApplePay or GooglePay?",
    ans: "Both ApplePay and GooglePay are enabled.",
  },
];

const FAQs = () => {
  return (
    <div className="my-24 gap-6 md:flex justify-between text-zebec-primary">
      <div className="max-w-[335px] lg:max-w-[538px]">
        <h1 className="text-[25px] lg:text-[40px] leading-[130%]">
          Welcome to the Future of Finance —Available Everywhere, for Everyone
        </h1>
        <p className="mt-5">Find Answers to Your Questions</p>
      </div>
      {/* faqs  */}
      <div className="mt-10 md:mt-0">
        {faqs.map((data, index) => {
          return <FaqCard {...data} key={index} index={index} />;
        })}
      </div>
    </div>
  );
};

export default FAQs;

import React from "react";
import { useTranslation } from "react-i18next";
import { ZebecBlackCardImg, ZebecInstantCardImg } from "../../../assets/images";
import { Button } from "../../shared/Button";
import * as Icons from "../../../assets/icons";
import { Link } from "react-router-dom";
// import { Dialog, Transition } from "@headlessui/react";

const zebecCardsInformation = [
  "prepaid",
  "no_fees",
  "one_time_use",
  "thirty_seconds",
  "visa_and_mastercard",
  "limit",
];

const ZebecCards = () => {
  const { t } = useTranslation();

  const blackCardsInformation = [
    {
      text: t(`zebecBlack:2nd_section.card2_info.debit`),
      className: "items-center",
    },
    {
      text: (
        <>
          {t(`zebecBlack:2nd_section.card2_info.load_fee`)}{" "}
          <span className="gradient-text-border-b">
            <Link to="/black-card-faqs" className="gradient-text">
              {t("zebecBlack:2nd_section.card2_info.fees")}
            </Link>
          </span>{" "}
          {t(`zebecBlack:2nd_section.card2_info.load_fee_end`)}
        </>
      ),
      className: "items-start",
    },
    {
      text: t(`zebecBlack:2nd_section.card2_info.reloadable`),
      className: "items-center",
    },
    {
      text: t(`zebecBlack:2nd_section.card2_info.activates_in`),
      className: "items-start",
    },
    {
      text: (
        <>
          {t(`zebecBlack:2nd_section.card2_info.mastercard`)}{" "}
          <span className="gradient-text-border-b">
            <Link to="/black-card-faqs" className="gradient-text">
              {t("zebecBlack:2nd_section.card2_info.worldwide")}{" "}
            </Link>
          </span>
        </>
      ),
      className: "items-center",
    },
    {
      text: t(`zebecBlack:2nd_section.card2_info.no_limit`),
      className: "items-center",
    },
  ];

  return (
    <>
      <div className="my-24 grid lg:grid-cols-2 gap-6">
        <div
          className="flex flex-col gap-y-2 rounded-[30px] p-10 pb-6 order-2 lg:order-1"
          style={{
            background:
              "linear-gradient(286.82deg, #C8A0FA -15.26%, #1E50FF 124.91%)",
          }}
        >
          <div className="">
            <h3 className="text-zebec-primary-light text-[28px] font-normal">
              {t("zebecBlack:2nd_section.card1_head")}
            </h3>
            <p className="mt-4 text-base leading-5 font-normal max-w-md text-zebec-primary-light">
              {t("zebecBlack:2nd_section.card1_desc")}
            </p>
          </div>
          <div className="mt-4 flex flex-wrap 2xl:flex-nowrap items-end gap-6">
            <img src={ZebecInstantCardImg} className="max-w-sm w-full" alt="" />
            <Link
              to={"/zebec-silver"}
              className="hidden md:block lg:hidden xl:block"
            >
              <Button
                title={t("zebecBlack:hero_section.zebec_silver")}
                className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary"
                startIcon={
                  <div
                    className={
                      "w-10 h-10 rounded-full bg-primary grid place-content-center"
                    }
                  >
                    <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </Link>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8 mt-6">
            {zebecCardsInformation.map((info, index) => {
              return (
                <div key={index} className="flex items-center gap-x-2">
                  <Icons.CircleTickIcon className="shrink-0" />
                  <p className="text-base text-white">
                    {t(`zebecBlack:2nd_section.card1_info.${info}`)}
                  </p>
                </div>
              );
            })}
          </div>
          <Link
            to={"/zebec-silver"}
            className="block md:hidden lg:block xl:hidden mt-3"
          >
            <Button
              title={t("zebecBlack:hero_section.zebec_silver")}
              className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary"
              startIcon={
                <div
                  className={
                    "w-10 h-10 rounded-full bg-primary grid place-content-center"
                  }
                >
                  <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                </div>
              }
            />
          </Link>
        </div>
        <div
          className="flex flex-col gap-y-2 rounded-[30px] p-10 pb-6 order-1 lg:order-2"
          style={{
            background:
              "linear-gradient(113.92deg, #515B70 5.63%, #24203F 136.53%)",
          }}
        >
          <div className="">
            <h3 className="text-zebec-primary-light text-[28px] font-normal">
              {t("zebecBlack:2nd_section.card2_head")}
            </h3>
            <p className="mt-4 text-base leading-5 font-normal max-w-lg text-zebec-primary-light">
              {t("zebecBlack:2nd_section.card2_desc")}
            </p>
          </div>
          <div className="mt-4 flex items-end flex-wrap 2xl:flex-nowrap  gap-6">
            <img src={ZebecBlackCardImg} className="max-w-sm w-full" alt="" />
            <Link
              to="https://zebec.mobilum.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="hidden md:block lg:hidden xl:block"
            >
              <Button
                title={t("common:Zebec_Black")}
                className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary"
                startIcon={
                  <div
                    className={
                      "w-10 h-10 rounded-full bg-primary grid place-content-center"
                    }
                  >
                    <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </Link>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8 mt-6">
            {blackCardsInformation.map((info, index) => {
              return (
                <div className="" key={index}>
                  <div className={`flex gap-x-2 ${info.className}`}>
                    <Icons.CircleTickIcon className="shrink-0" />
                    <p className="text-base text-white">{info.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <Link
            to="https://zebec.mobilum.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="block md:hidden lg:block xl:hidden mt-3"
          >
            <Button
              title={t("zebecBlack:hero_section.zebec_black")}
              className="green-button-border -mb-2 !text-sm lg:!text-base rounded-full text-primary"
              startIcon={
                <div
                  className={
                    "w-10 h-10 rounded-full bg-primary grid place-content-center"
                  }
                >
                  <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                </div>
              }
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default ZebecCards;

import Home from "./components/home/Home";
import Layout from "./components/layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import CountriesNetwork from "./components/countries-network/CountriesNetwork";
import ZebecInstantCardFaqs from "./components/zebec-instant-card-faq/ZebecInstantCardFaqs";
import Referral from "./components/referral/Referral";
import Bonk from "./components/bonk/Bonk";
import Legend from "./components/legend/Legend";
import PageNotFound from "./components/404/PageNotFound";
import ScrollToTop from "./components/shared/ScrollToTop";
import SillyDragon from "./components/silly-dragon/SillyDragon";
import { useEffect } from "react";
import { initGA, logPageView } from "./ga";
import Burrrd from "./components/burrrd/Burrrd";
import ZBCIntegrationPromo from "./components/zbc-integration-promo/ZBCIntegrationPromo";
import MagicInternetMoney from "./components/magic-internet-money/Magic";
import Brett from "./components/brett/Brett";
// import InstantCard from "./components/instant-card";
import BlackCardFaqs from "./components/black-card-faqs";
import TwitterCard from "./components/seo/TwitterCard";
import SchemaOpenGraph from "./components/seo/SchemaOpenGraph";
import ZebecSilver from "./components/silver";

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your GA4 property ID
    initGA("G-0TP93V3F5B");

    // Log initial pageview
    logPageView();
  }, []);
  return (
    <Layout>
      <TwitterCard
        title={"Zebec Cards"}
        description={
          "Decentralized infrastructure network for seamless flows of real-world value."
        }
        image={
          "https://pbs.twimg.com/profile_images/1773695810464841728/JJV-0p2A_400x400.jpg"
        }
        site={"@ZebecCard"}
      />
      <SchemaOpenGraph />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/black-card-faqs" element={<BlackCardFaqs />}></Route>
        <Route path="/visa-master" element={<CountriesNetwork />}></Route>
        {/* <Route path="/instant-card" element={<InstantCard />}></Route> */}
        <Route path="/zebec-silver" element={<ZebecSilver />}></Route>
        <Route path="/card-faqs" element={<ZebecInstantCardFaqs />}></Route>
        <Route path="/legend" element={<Referral />}></Route>
        <Route path="/bonk" element={<Bonk />}></Route>
        <Route path="/silly-dragon" element={<SillyDragon />}></Route>
        <Route path="/burrrd" element={<Burrrd />}></Route>
        <Route
          path="/zbc-integration-promo"
          element={<ZBCIntegrationPromo />}
        ></Route>
        <Route
          path="/magic-internet-money"
          element={<MagicInternetMoney />}
        ></Route>
        <Route path="/brett" element={<Brett />}></Route>
        <Route path="/:id" element={<Legend />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Layout>
  );
}

export default App;
